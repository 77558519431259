import Api from '@/services/Api'

export default {
  viewPartner(partnerSlug) {
    return Api().get(`/api/partner/slug/${partnerSlug}`)
  },

  viewCommunities() {
    return Api().get(`/api/admin/communities`)
  },

  createOrderAndCustomer(orderInfo) {
    return Api().post(`/api/partner/createOrderAndCustomer`, orderInfo)
  },

  razorpayPaymentCallback(paymentInfo) {
    return Api().get(`/api/partner/payments/razorpaycallback`, {'params': paymentInfo})
  },

  viewOrderDetails(orderInfo) {
    return Api().post(`/api/partner/viewCustomerOrderDetails`, orderInfo)
  }
}
